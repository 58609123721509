<template>
    <section class="coming-soon-section bg-holder">
      <video autoplay muted loop id="background-video">
        <source :src="require('../assets/images-saha/comingsoon.mp4')" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 text-center">
            <div class="inner-content">
        
             
              <div class="countdown-wrapper countdown-light">
                <div class="countdown">
                  <span class="days">{{ days }}</span>
                  <p class="days_ref">Days</p>
                </div>
                <div class="countdown">
                  <span class="hours">{{ hours }}</span>
                  <p class="hours_ref">Hours</p>
                </div>
                <div class="countdown">
                  <span class="minutes">{{ minutes }}</span>
                  <p class="minutes_ref">Minutes</p>
                </div>
                <div class="countdown">
                  <span class="seconds">{{ seconds }}</span>
                  <p class="seconds_ref">Seconds</p>
                </div>
              </div>
              <p class="coming-soon-description">
                Something exciting is on the horizon for those who live and breathe games. We can't reveal much yet, but if you're passionate about immersive experiences and leveling up your game, you won't want to miss this. Stay tuned for what's next—you’ll want to be the first to know!
              </p>
                 </div>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: "ComingSoon",
    data() {
      return {
        // Replace this date with your target launch date
        launchDate: new Date("2024-11-31T00:00:00").getTime(),
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      };
    },
    mounted() {
      this.startCountdown();
    },
    methods: {
      startCountdown() {
        const timer = setInterval(() => {
          const now = new Date().getTime();
          const distance = this.launchDate - now;
  
          if (distance < 0) {
            clearInterval(timer);
            this.days = "00";
            this.hours = "00";
            this.minutes = "00";
            this.seconds = "00";
            return;
          }
  
          this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
          this.hours = Math.floor(
            (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
        }, 1000);
      },
    },
  };
  </script>
  
  <style scoped>
  .coming-soon-section {
    position: relative;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    overflow: hidden;
  }
  .coming-soon-description{
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px;
    background: linear-gradient(60deg, #ffffff -20%, #77BEFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  #background-video {
    position: absolute;
    top: -200px;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: -1;
    object-fit: cover;
  }
  
  
  </style>
  