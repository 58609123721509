<template>
  <footer class="site-footer footer-dark">
    <div class="footer-main">
      <h2 class="title text-center pb-5" style="color:white"> Contact Us</h2>
      <div class="container">
        <div class="row">
          <!-- Address Info -->
          <div class="col-xl-5 col-md-7">
            <div class="widget widget-address-info">
              <h5 class="widget-title">Where To Find Us</h5>
              <ul class="address-info-list">
                <li>
                  <i class="icon">
                    <img class="img-fluid" :src="require('@/assets/svg/address-info-contacts.svg')" alt="Address Icon" />
                  </i>
                  <span class="info">
                    <span> HD-278, SY. 130P & 115/1P, Nanakramguda Rd, Fin. Dist., <br /> Seri Lingampally, K.V. Rangareddy, Telangana, India, 500032.</span>
                  </span>
                </li>
                <li>
                  <i class="icon">
                    <img class="img-fluid" :src="require('@/assets/svg/address-info-headphone.svg')" alt="Phone Icon" />
                  </i>
                  <span class="info">
                    <span>+91 9014633153</span> Mon-fri 8:30am - 6:30pm
                  </span>
                </li>
                <li>
                  <i class="icon">
                    <img class="img-fluid" :src="require('@/assets/svg/address-info-email.svg')" alt="Email Icon" />
                  </i>
                  <span class="info">
                    <span>info@sahanexus.com</span> 24x7 online support
                  </span>
                </li>
              </ul>
            </div>
          </div>

          <!-- Newsletter and Quick Links -->
          <div class="col-xl-5 col-md-7">
            <div class="widget">
              <h5 class="widget-title">Newsletter</h5>
              <div class="widget widget-newsletter mb-4 pb-2">
                <form class="newsletter-form" @submit.prevent="submitNewsletter">
                  <input type="email" v-model="email" class="form-control" placeholder="Enter Your Email" required />
                  <button type="submit" class="subscribe-btn">
                    <v-icon>mdi-send</v-icon>
                  </button>
                </form>
              </div>
              <div class="widget widget-menu">
                <h6 class="widget-title">Quick Links</h6>
                <ul class="list-unstyled list-col-3 mb-0">
                  <li><a href="/">Home</a></li>
                  <li><a href="/contact">Quotation</a></li>
                  <li><a href="/products">Products</a></li>
                  <li><a href="/pricing">Pricing</a></li>
                  <li><a href="/coming-soon">Coming Soon</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Footer Bottom -->
    <div class="footer-copyright">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <p>
              <a class="footer-logo" href="#">
                <img class="image-size " :src="require('@/assets/images-saha/logobrand.png')" alt="logo" />
              </a>
            </p>
          </div>
          <div class="col-lg-6 text-lg-end">
            <p>Develop and design by <a href="#">SAHA NEXUS</a></p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterVue",
  data() {
    return {
      email: "",
    };
  },
  methods: {
    async submitNewsletter() {
      if (this.email) {
        try {
          const formUrl = "https://formspree.io/f/xanyyqzo"; // Replace with your Formspree form ID

          const response = await fetch(formUrl, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ email: this.email }),
          });

          if (response.ok) {
            alert(`Successfully subscribed with ${this.email}`);
            this.email = ''; // Reset email field
          } else {
            alert('Subscription failed. Please try again.');
          }
        } catch (error) {
          console.error("Error subscribing to newsletter:", error);
        }
      } else {
        alert("Please enter a valid email address.");
      }
    },
  },
};
</script>

<style scoped>
.image-size {
  max-width: 350px;
}
.subscribe-btn {
  background-color: #0104ac;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
}
.subscribe-btn:hover {
  background-color: black;
}
</style>
