<template>
  <v-app-bar app color="black" dark>
    <!-- 
    ite logo and hamburger container -->
    <div class="header-container">
      <header class="header header-default header-absolute header-sticky">
        <div class="header-inner">
          <div class="site-logo">
            <a class="navbar-brand" href="/">
              <img
                class="img-fluid image-size"
                :src="require('../assets/images-saha/logobrand.png')"
                alt="logo"
              />
            </a>
          </div>

          <!-- Main menu for larger screens -->
          <div class="site-menu d-none d-xl-block">
            <ul class="main-menu">
              <li v-for="item in menuItems" :key="item.text" class="nav-item">
                <router-link :to="item.to" class="nav-link">{{ item.text }}</router-link>
              </li>
            </ul>
          </div>

          <!-- Custom Hamburger Menu for smaller screens -->
          
          <div class="hamburger-menu d-xl-none" @click="toggleDrawer">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </header>
    </div>
  </v-app-bar>

  <!-- Navigation Drawer -->
  
  <v-navigation-drawer v-model="drawer" temporary right class="site-menu1">
    <v-list class="main-menu">
      <v-list-item v-for="item in menuItems" :key="item.text" @click="drawer = false" class="nav-item">
        <v-list-item-title>
          <router-link class="nav-link1" :to="item.to">{{ item.text }}</router-link>
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: "AppBar",
  data() {
    return {
      drawer: false, // controls the drawer
      menuItems: [
        { text: "Home", to: "/" },
        { text: "Products", to: "/products" },
        { text: "Pricing", to: "/pricing" },
        { text: "SAHA Innovations", to: "/coming-soon" },
        { text: "About Us", to: "/about-us" },
        { text: "Contact", to: "/contact" },
      ],
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer; // Toggle the drawer state
    },
  },
};
</script>

<style scoped>
/* Header container to align logo and hamburger horizontally */
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 15px;
}
.site-menu1 {
  width: 100%;
  position: relative;
  z-index: 9;

  background-color: #052235;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  /* Site Main Logo */
  /* Site Main Menu */
  /* Site Action Button */
  /* Sticky Header */
}
.nav-link1{
  text-transform: uppercase;
  font-family: "Russo One", sans-serif;
  font-size: 18px;
 
  color: #77BEFF;
  position: relative;
}
/* Custom Hamburger Menu Styling */
.hamburger-menu {
  align-self: center;
  display: flex;
  flex-direction: column;
  width: 50px;
  cursor: pointer;
}

.hamburger-menu span {
  background: #fff;
  border-radius: 10px;
  height: 4px;
  margin: 5px 0;
  transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.hamburger-menu span:nth-of-type(1) {
  width: 50%;
}

.hamburger-menu span:nth-of-type(2) {
  width: 100%;
}

.hamburger-menu span:nth-of-type(3) {
  width: 75%;
}

.hamburger-menu input[type="checkbox"] {
  display: none;
}

.hamburger-menu input[type="checkbox"]:checked ~ span:nth-of-type(1) {
  transform-origin: bottom;
  transform: rotateZ(45deg) translate(8px, 0px);
}

.hamburger-menu input[type="checkbox"]:checked ~ span:nth-of-type(2) {
  transform-origin: top;
  transform: rotateZ(-45deg);
}

.hamburger-menu input[type="checkbox"]:checked ~ span:nth-of-type(3) {
  transform-origin: bottom;
  width: 50%;
  transform: translate(18px, -8px) rotateZ(45deg);
}

/* Adjust the image size */
.image-size {
  width: 150px;
}




</style>
