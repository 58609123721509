<template lang="">
 
    <div class="site-content" style="color:white" >
        <!--=================================
        Page Header -->
    <div class="inner-header bg-holder" :style="{ backgroundImage: `url(${require('../assets/banner/inner-header/page-header-01.jpg')})` }">
          <div class="container" color="white">
            <div class="row  justify-content-center">
              <div class="col-md-12 text-center">
                <h1 class="title">Case Studies</h1>
                
              </div>
            </div>
          </div>
        </div>

        <div class="content-wrapper">

          <!--=================================
          Case Studies -->
          <section class="space-pt" >
            <div class="container">
              <div class="row justify-content-between">
                <div class="col-lg-3 col-sm-4">
                  <div class="section-title">
                    <span class="sub-title"><img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt=""> Case Study</span>
                    <h2 class="title">Overview</h2>
                  </div>
                  <div class="text-end me-4 pt-3 pe-2">
                    <img class="img-fluid" :src="require('../assets/case-studies/shape.png')" alt="#">
                  </div>
                </div>
                <div class="col-lg-8 col-sm-8">
                  <p class="fw-600 mt-md-5 pt-md-5 pt-3 mb-4 pb-2">Commitment is something that comes from understanding that everything has its price and then having the willingness to pay that price. This is important because nobody.</p>
                  <p>Franklin’s extraordinary success in life and politics can be attributed to his perseverance to overcome his personal liabilities, and his desire to constantly become better. Next time you really want to achieve something, take time to focus on your own personal journal. What is your temptation that is standing in your way to greatness? What can you do to form the habit of becoming a success?</p>
                </div>
              </div>
               <div class="row">
                <div class="col-md-12">
                  <img class="img-fluid radius-10 space-medium-ptb" :src="require('../assets/case-studies/07.jpg')" alt="#">
                </div>
               </div>
            </div>
          </section>
          <!--=================================
          Case Studies -->

          <!--=================================
          Case Info -->
          <section class="case-info-section space-mb">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <div class="case-info">
                    <ul>
                      <li>
                         <h6>Client</h6>
                         Developer
                      </li>
                      <li>
                         <h6>Categories</h6>
                         It agency
                      </li>
                      <li>
                         <h6>Budgets</h6>
                         $156.110
                      </li>
                      <li>
                         <h6>Location</h6>
                         New jersey, USA
                      </li>
                      <li>
                         <h6>Project Url</h6>
                         <a href="#">http://www.example.com</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!--=================================
          Case Info -->

          <!--=================================
          Case Studies -->
          <section class="space-medium-pt ellipse-top">
            <div class="space-medium-pb ellipse-bottom">
              <div class="container">
                <div class="row">
                  <div class="col-md-4">
                    <div class="sticky-top" style="top: 80px;">
                    <div class="section-title i">
                      <span class="sub-title"><img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt=""> Case Study</span>
                      <h2 class="title">Challenge</h2>
                    </div>
                   </div>
                  </div>
                  <div class="col-md-8">
                    <div class="challenge-iteam">
                      <h6 class="title"><img class="img-fluid" :src="require('../assets/case-studies/arrow-right.png')" alt="">This is perhaps the single biggest obstacle that all of us must overcome in order to be successful.</h6>
                      <p>We all carry a lot of baggage, thanks to our upbringing. The majority of people carry with them, an entire series of self-limiting beliefs that will absolutely stop, and hold them back from, success. Things like “I’m not good enough”, “I’m not smart enough”, “I’m not lucky enough”, and the worst, “I’m not worthy” are but a few of the self-limiting beliefs I have encountered. We carry them with us like rocks in a knapsack, and then use them to sabotage our success. So, how twisted is that?.</p>
                      <p>One of the main areas that I work on with my clients is shedding these non-supportive beliefs and replacing them with beliefs that will help them to accomplish their desires.</p>
                    </div>
                    <div class="challenge-iteam">
                      <h6 class="title"><img class="img-fluid" :src="require('../assets/case-studies/arrow-right.png')" alt="">And it’s not just parents that are the cause – teachers, friends, clergy members or anyone else that has influence in a child’s life can be a contributor to these self-limiting beliefs!</h6>
                      <p>“Nothing changes until something moves” – this is the battle cry of author and journalist Robert Ringer. And he is absolutely correct. Not all of the decision-making, clarity, planning, focus and belief in the world, will get you to where you want to be, without taking action!</p>
                    </div>
                    <div class="challenge-iteam mb-0">
                      <h6 class="title"><img class="img-fluid" :src="require('../assets/case-studies/arrow-right.png')" alt="">Have some fun and hypnotize yourself to be your very own “Ghost of Christmas future” and see what the future.</h6>
                      <p>The first thing to remember about success is that it is a process – nothing more, nothing less. There is really no magic to it and it’s not reserved only for a select few people. As such, success really has nothing to do with luck, coincidence or fate.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!--=================================
          Case Studies -->

          <!--=================================
          Case Studies -->
          <section class="space-medium-ptb ellipse-bottom">
            <div class="container">
              <div class="row">
                <div class="col-sm-5 mb-4 mb-sm-0">
                  <img class="img-fluid radius-10" :src="require('../assets/case-studies/case-study-single-01.jpg')" alt="#">
                </div>
                <div class="col-sm-7">
                   <img class="img-fluid radius-10" :src="require('../assets/case-studies/case-study-single-02.jpg')" alt="#">
                </div>
              </div>
            </div>
          </section>
          <!--=================================
          Case Studies -->

          <!--=================================
          Solution -->
          <section class="space-medium-pb">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                  <h2>Solution</h2>
                  <p class="mb-4 pb-3 text-white fw-600">The best way is to develop and follow a plan. Start with your goals in mind and then work backwards to develop the plan. What steps are required to get you to the goals? Make the plan as detailed as possible.</p>
                  <p class="mb-4">Imagine reaching deep inside you for all the strength and wisdom that you need to make this decision today. As you do so, imagine that when you choose to make that decision that deep inside your mind you are switching off the alternative path,</p>
                  <p class="mb-0">Step out on to the path to your left. Where there is no change. Briefly imagine that you are not going to live and discover your unfulfilled dreams. Instead, you continue doing what you have in the past. What will life be like in 10 years time? Step out, every step you take you get older, days pass, weeks pass. Notice how your body is, how you feel about staying on this path.</p>
                </div>
              </div>
            </div>
          </section>
          <!--=================================
          Solution -->

          <!--=================================
          Results -->
          <section class="space-pb ellipse-bottom">
            <div class="container">
              <div class="row">
                <div class="col-md-12">
                    <h2>Results</h2>
                    <p class="mb-4 pb-3 text-white fw-600">There are basically six key areas to higher achievement. Some people will tell you there are four while others may tell you there are eight. One thing for certain though, is that irrespective of the number.</p>
                    </div>
                </div>
                <div class="row">
                  <div class="col-md-4 mb-3 mb-md-0">
                    <h3 class="gradient-text-1 mb-2">81%</h3>
                    <p class="fw-600 mb-0">A totally new way to grow your business</p>
                  </div>
                  <div class="col-md-4 mb-3 mb-md-0">
                    <h3 class="gradient-text-1 mb-2">Improved</h3>
                    <p class="fw-600 mb-0">We are adding extra value for your business</p>
                  </div>
                  <div class="col-md-4">
                    <h3 class="gradient-text-1 mb-2">Increased</h3>
                    <p class="fw-600 mb-0">Award-winning website design & creative digital agency services</p>
                  </div>
               </div>
            </div>
          </section>
          <!--=================================
          Results -->

        </div>
      </div>
</template>
<script>
export default {
    name:'CaseStudies'
    
}
</script>
<style lang="">
    
</style>