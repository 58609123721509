import { createApp } from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import { loadFonts } from './plugins/webfontloader';
import router from './router';
import { createMetaManager } from 'vue-meta';

loadFonts();
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';

// Create the meta manager
const metaManager = createMetaManager();

createApp(App)
  .use(vuetify)
  .use(router)
  .use(metaManager) // Add vue-meta here
  .mount('#app');
