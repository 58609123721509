import { createRouter, createWebHistory } from 'vue-router';
import LandingPage from '../components/LandingPage.vue';
import ProductsCard from "../components/ProductsCard.vue";
import PricingCard from "../components/PricingCard.vue";
import CaseStudies from "../components/CaseStudies.vue";
import BlogPage from "../components/BlogPage.vue";
import ContactPage from "../components/ContactPage.vue";
import CommingSoon from "../components/CommingSoon.vue";
import NotFoundPage from "../components/NotFoundPage.vue";  // Custom 404 page
import AboutUs from '@/components/AboutUs.vue';
// Define your routes
const routes = [
  {
    path: '/',
    component: LandingPage,
    meta: {
      title: 'Home - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'Welcome to Saha Nexus, your partner in innovative solutions.' },
        { name: 'keywords', content: 'home, Saha Nexus, innovative solutions' },
      ],
    },
  },
  {
    path: '/products',
    component: ProductsCard,
    meta: {
      title: 'Products - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'Explore our range of innovative products at Saha Nexus.' },
        { name: 'keywords', content: 'products, Saha Nexus, innovative' },
      ],
    },
  },
  {
    path: '/pricing',
    component: PricingCard,
    meta: {
      title: 'Pricing - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'Discover our competitive pricing for innovative solutions.' },
        { name: 'keywords', content: 'pricing, Saha Nexus, competitive' },
      ],
    },
  },
  {
    path: '/case-studies',
    component: CaseStudies,
    meta: {
      title: 'Case Studies - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'Read our case studies to see how we have helped our clients.' },
        { name: 'keywords', content: 'case studies, Saha Nexus, clients' },
      ],
    },
  },
  {
    path: '/blog',
    component: BlogPage,
    meta: {
      title: 'Blog - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'Stay updated with the latest news and insights from Saha Nexus.' },
        { name: 'keywords', content: 'blog, Saha Nexus, news' },
      ],
    },
  },
  {
    path: '/coming-soon',
    component: CommingSoon,
    meta: {
      title: 'Coming Soon - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'Exciting new features are coming soon to Saha Nexus!' },
        { name: 'keywords', content: 'coming soon, Saha Nexus, features' },
      ],
    },
  },
  {
    path: '/contact',
    component: ContactPage,
    meta: {
      title: 'Contact Us - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'Get in touch with us at Saha Nexus for inquiries and support.' },
        { name: 'keywords', content: 'contact, Saha Nexus, support' },
      ],

    },
  },
  {
    path: '/about-us',
    component: AboutUs,
    meta: {
      title: 'About Us - Saha Nexus',
      metaTags: [
        { name: 'description', content: 'About SAHA Nexus corporation ltd.' },
        { name: 'keywords', content: 'About SAHA Nexus, Saha Nexus corporation ltd., support' },
      ],

    },
  },
  // Catch-all 404 route
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundPage,
    meta: {
      title: '404 - Page Not Found',
    },
  },
];

// Create the router instance
const router = createRouter({
  history: createWebHistory(), // Enables history mode for clean URLs
  routes, // Short for `routes: routes`
  
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      };
    }
    return { top: 0 };
  }
});

// Set up beforeEach hook for setting the title
router.beforeEach((to) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // Set meta tags
  const metaTags = to.meta.metaTags || [];
  metaTags.forEach(tag => {
    const element = document.createElement('meta');
    Object.keys(tag).forEach(key => {
      element.setAttribute(key, tag[key]);
    });
    document.head.appendChild(element);
  });

  // Cleanup meta tags from previous route
  const existingMetaTags = document.head.querySelectorAll('meta');
  existingMetaTags.forEach(existingTag => {
    if (!metaTags.some(tag => tag.name === existingTag.getAttribute('name'))) {
      document.head.removeChild(existingTag);
    }
  });
});

// Export the router
export default router;
