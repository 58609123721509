<template lang="">
    <div>
      <div
        class="inner-header bg-holder"
        :style="{
          backgroundImage: `url(${require('../assets/images-saha/about.png')})`,
          
        }"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-md-12 text-center">
              <h1 class="title">About SAHA NEXUS</h1>
              <p class="font-white">Our Expertise. Know more about what we do</p>
            </div>
          </div>
        </div>
      </div>
  
      <div class="content-wrapper">
        <!--=================================
              About Info -->
        <section class="space-pt z-index-2">
          <div class="container">
            <div class="row justify-content-between">
              <div class="col-lg-12 mb-5 mb-lg-0">
                <div class="section-title pb-0 pb-lg-4">
                  <span class="sub-title"
                    ><img
                      class="img-fluid"
                      :src="require('../assets/subtitle-icon.png')"
                      alt=""
                    />
                    About Us</span
                  >
                  <h2 class="title font-white">Your experience is everything to us</h2>
                </div>
                <div class="row">
                 
                  <div class=" center pa-5">
                    <p class="subtitle font-white">
                      At <strong>Saha Nexus</strong>, we are innovators at heart, dedicated to building unique, cutting-edge products that seamlessly combine real-time systems (RTS), entertainment, and groundbreaking technologies. Our products aren’t just tools; they redefine experiences, setting new standards in innovation and functionality. From interactive digital platforms to immersive entertainment solutions, we focus on delivering creations that captivate and inspire.
                    </p>
                    <p class="subtitle font-white">
                      Our in-house products are designed with a deep commitment to creativity, pushing boundaries to offer something truly unique. Whether it’s through real-time engagement or entertainment-driven solutions, every product we build represents the future of digital experiences.
                    </p>
                    <p class="subtitle font-white">
                      <strong>Empowering Industries Through Innovation</strong><br>
                      While our primary focus is on building our own transformative products, we also collaborate with businesses and individuals looking to harness the power of technology to revolutionize industries. We bring the same passion for innovation to our collaborations, ensuring every project benefits from our expertise.
                    </p>
                    <p class="subtitle font-white">
                      <strong>How We Collaborate with Clients:</strong>
                      <ul class="font-white">
                        <li><strong>Share Your Vision:</strong> When businesses approach us, we start by understanding their ideas and goals. We see every project as an opportunity to innovate, explore, and create something truly impactful.</li>
                        <li><strong>Collaborate and Plan:</strong> We don’t just offer solutions—we become partners in your success. Through collaboration, we develop an actionable roadmap tailored to your needs, providing technical insight and strategic planning.</li>
                        <li><strong>Refine and Execute:</strong> With continuous feedback and optimization, we ensure that the final product not only meets but exceeds expectations. From refinement to deployment, we guarantee quality, precision, and excellence.</li>
                      </ul>
                    </p>
                    <p class="subtitle font-white">
                      At <strong>Saha Nexus</strong>, our products shape industries and enhance lives. We create, innovate, and empower—whether through our own groundbreaking products or through partnerships that help others achieve their digital goals.
                    </p>
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'AboutUs'
  };
  </script>
  
  <style scoped>
  .font-white {
    color: white;
  }
  </style>
  