<template>
  <div class="site-content">
    <div
      class="inner-header bg-holder"
      :style="{
        backgroundImage: `url(${require('../assets/images-saha/prodsbg.png')})`,
      }"
    >
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <div class="conent">
            <h1 class="title">
              <!-- <img class="img-fluid banner-icon" :src="require('../assets/banner/banner-02/01.png')" alt="Banner Icon" /> -->

              Our Products
              
            </h1>
          </div>
          </div>
        </div>
      </div>
    </div>
 

    <div class="content-wrapper">
      <section class="space-ptb ellipse-bottom">
        <div class="container">
          <v-tabs
          v-model="tab"
          bg-color="black"
        >
          <v-tab value="one">Live Projects</v-tab>
          <v-tab value="two">Product Templates</v-tab>
        
        </v-tabs>
        <v-tabs-window v-model="tab">
          <v-tabs-window-item value="one">
            <div class="sample-sites mt-5">
              <h5 class="service-title">Projects</h5>
              <div class="row" v-for="(site, index) in sampleSites" :key="index">
                <!-- Iframe takes col-7 space -->
                <div class="col-xl-16 mb-4">
                  <h3 class="link-style">{{ site.name }}  <button @click="openSite(site.url)" class="arrow-button ">Visit Site</button></h3>
                  <div class="iframe-container">
                    <iframe :src="site.url" class="sample-site-iframe"></iframe>
                   
                  </div>
                </div>
                <!-- Site Details takes col-5 space -->
                <div class="col-lg-10 mb-4">
                  
                  
                  <p class="description">{{ site.description }}</p>
                  <div class="marquee-container">
                    <div class="marquee">
                   .................................................................. .....................................................
                   .................................................................. .....................................................
                    </div>
                  </div>
                  <!-- <p><strong>Technologies Used:</strong> {{ site.technologies }}</p> -->
                  <!-- <p><strong>Key Features:</strong> {{ site.features }}</p> -->
            
                </div>
              </div>
            </div>
        
        </v-tabs-window-item>
        <v-tabs-window-item value="two">
          
          <div class="row">
            <!-- Sidebar with Product Categories (Mobile-first) -->
            <div class="col-12 col-lg-3 order-lg-1 mb-4 mb-lg-0">
              <div class="sidebar">
                <div class="widget widget-categories">
                  <h5 class="widget-title">Categories</h5>
                  <ul class="categories-list">
                    <li v-for="(product, index) in products" :key="index">
                      <a href="#" @click.prevent="activeProductIndex = index">
                        {{ product.name }}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        
            <!-- Product Details (Video and Description) -->
            <div class="col-12 col-lg-8 order-lg-2">
              <div class="service-single">
                <!-- Product Video -->
                <div class="service-img">
                  <video
                    v-if="activeProduct.video"
                    :key="activeProduct.video"
                    autoplay
                    muted
                    loop
                    class="product-video"
                    preload="metadata"
                  >
                    <source :src="activeProduct.video" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
        
                <!-- Product Description -->
                <div class="service-content mt-4">
                  <h5 class="service-title">{{ activeProduct.name }}</h5>
                  <div class="description">
                    <p>{{ activeProduct.description }}</p>
                  </div>
                  <blockquote class="blockquote mb-0">
                    <div class="blockquote-content">
                      <p>{{ activeProduct.quote }}</p>
                      <div class="blockquote-author">
                        <div class="blockquote-name">{{ activeProduct.author }}</div>
                      </div>
                    </div>
                  </blockquote>
                </div>
              </div>
            </div>
        
       
          </div>
        </v-tabs-window-item>
        </v-tabs-window>
      </div>
      </section>
      
    </div>
  </div>
</template>

<script>

export default {
  name: "ProductsPage",

  data() {
    return {
      tab: null,
      sampleSites: [
    {
    name: "S GRILL",
    url: "https://sgrill-saha.vercel.app/",
    description: "S-Grill is a dynamic platform for food lovers to explore our menu, place online orders, and connect with us. Designed with an elegant interface, the site features menu filters, online ordering, and easy contact options, making it the perfect destination for grill enthusiasts looking for a convenient and flavorful experience",
    features: "Menu View, Online Ordering, Blog",
  },
  {
    name: "Hotel Website",
    url: "https://sahahotels2-0.vercel.app/",
    description: "A sleek and user-friendly hotel website that showcases rooms, services, and amenities. Visitors can easily browse through room details, special offers, and book their stays with confidence. A great choice for hotels looking to create a memorable online experience for guests.",
    features: "Responsive Design, Booking System, Content recommendations",
  },
  {
  name: "Home Design and Architecture",
  url: "https://sahahomes.vercel.app/",
  description: "A modern and sleek website showcasing home design and architectural services. The site highlights the latest trends in home design, featuring detailed portfolios, project galleries, and client testimonials. Ideal for architecture firms and interior designers looking to display their projects and attract potential clients.",
  features: "Responsive design, Portfolio gallery, Client testimonials",
},
  {
    name: "Coffee Shop",
    url: "https://sahanexuscorp.github.io/sahacoffee/",
    description: "A modern and inviting website for a coffee shop, featuring an easy-to-navigate menu and stunning visuals. The site highlights the shop’s ambiance, special brews, and offers a seamless experience for visitors to explore. Perfect for showcasing coffee products and promotions.",
    features: "Responsive Design and SEO Optimized",
  },
  {
    name: "Dental Website",
    url: "https://sahanexuscorp.github.io/dentaltemplate/",
    description: "A professional website for a dental clinic, offering detailed information on services, appointment scheduling, and patient testimonials. The design ensures ease of access to important resources, helping patients connect with the clinic effortlessly. Ideal for clinics aiming to enhance their digital presence.",
    features: "Editable articles, Multilingual support, Free content",
  },
  
  {
    name: "Podcaster",
    url: "https://sahanexuscorp.github.io/podcaster/",
    description: "A captivating platform for podcasters to share episodes, engage with listeners, and grow their audience. The site is designed to highlight featured podcasts, provide subscription options, and foster community interaction. Ideal for podcasters aiming to increase their reach and visibility.",
    features: "Episode management, Subscription options, Collaboration tools",
  },
 
]

,
      
      activeProductIndex: 0, // Default to the first product
      products: [
  {
    name: "Grill Restaurant",
    description:
      "A restaurant specializing in grilled delights, offering a fiery and flavorful dining experience.",
    video: require("../assets/images-saha/grill.mp4"),
    quote: "Where taste meets the grill.",
    author: "- GrillMaster Reviews",
  },
  {
    name: "Sports and GYM",
    description:
      "A state-of-the-art gym offering the best equipment and trainers to keep you in shape.",
    video: require("../assets/images-saha/gym.mp4"),
    quote: "Your fitness journey starts here.",
    author: "- Fitness World",
  },
  {
    name: "Coffee House",
    description:
      "A cozy coffee house serving freshly brewed coffee and delicious snacks for a perfect break.",
    video: require("../assets/images-saha/coffee.mp4"),
    quote: "Wake up and smell the coffee.",
    author: "- Coffee Enthusiasts",
  },
  {
    name: "Real Estate",
    description:
      "Explore premium properties and find your dream home with our trusted real estate services.",
    video: require("../assets/images-saha/house.mp4"),
    quote: "Your key to a perfect home.",
    author: "- Real Estate Hub",
  },
  {
    name: "Auto Services and Sales",
    description:
      "Comprehensive car services, from sales to maintenance, all under one roof.",
    video: require("../assets/images-saha/auto.mp4"),
    quote: "Driving excellence in every ride.",
    author: "- Auto Express",
  },
],

    };
  },
  methods: {
    openSite(url) {
      window.open(url, '_blank');
    }
  },
  computed: {
    activeProduct() {
      return this.products[this.activeProductIndex];
    },
  },
};
</script>

<style scoped>


.marquee-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.marquee {
  display: flex;
  white-space: nowrap;
  animation: marquee 20s linear infinite;
}

.product-video {
  height: 80px !important; /* Adjust the width of the video as per your requirement */
}

@keyframes marquee {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}


.products-card {
  padding: 16px;
}
.title{
  font-size: 3rem !important;
  
}
#background-video {
  right: 10px;

  max-width: 100%;
  max-height: 100%;
  z-index: -1;
  object-fit: contain;
}
.arrow-button {
  padding: 10px;
  font-size: 14px;
  margin-left: 20px;
  color: #007bff; /* Blue text color */
  background-color: black;

  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.6),  rgba(0, 123, 255, 0.2);
}
.description{
  font-size: 18px;
  font-family: "Jost", sans-serif;
}


.arrow-button:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 1), 0 0 60px rgba(0, 123, 255, 0.8), 0 0 120px rgba(0, 123, 255, 0.6);
}

.arrow-button:active {
  background-color: #333;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5), 0 0 15px rgba(0, 123, 255, 0.3), 0 0 30px rgba(0, 123, 255, 0.2);
}

.link-style{
font-size: 1.5rem;
  color:#007bff
}

.product-card {
  margin-bottom: 16px;
}
.sample-sites {
  margin: 30px;
  padding: 10px;
}
.glow-button {
  padding: 10px 20px;
  font-size: 16px;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px rgba(0, 123, 255, 0.6), 0 0 40px rgba(0, 123, 255, 0.4), 0 0 80px rgba(0, 123, 255, 0.2);
}

.glow-button:hover {
  box-shadow: 0 0 20px rgba(0, 123, 255, 1), 0 0 60px rgba(0, 123, 255, 0.8), 0 0 120px rgba(0, 123, 255, 0.6);
}

.glow-button:active {
  background-color: #0056b3;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5), 0 0 15px rgba(0, 123, 255, 0.3), 0 0 30px rgba(0, 123, 255, 0.2);
}
.iframe-container{
  height: 70vh;
  width: 80vw;
  
}
.sample-site-iframe {
  width: 70vw;
  height: 100%;
  border: none;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.sample-sites .col-md-6 {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.service-title {
  margin-bottom: 15px;
  font-size: 20px;
}
.service-img video {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.product-video {
  max-width: 100%;
  height: auto;
}

.sidebar {
  position: sticky;
  top: 20px;
}

.categories-list li a {
  cursor: pointer;
  display: block;
  padding: 10px 0;
}

.categories-list li a:hover {
  color: #007bff;
}

.service-content {
  padding-top: 20px;
}
</style>
