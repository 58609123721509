export default {
    directives: {
      attractHover: {
        mounted(el, binding) {
          const proximity = binding.value.proximity || 70;
          const magnetism = binding.value.magnetism || 3;
  
          el.style.transition = 'transform 0.1s ease-out';
  
          document.addEventListener('mousemove', (e) => {
            const rect = el.getBoundingClientRect();
            const distanceX = e.clientX - (rect.left + rect.width / 2);
            const distanceY = e.clientY - (rect.top + rect.height / 2);
            const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);
  
            if (distance < proximity) {
              const transformX = distanceX / magnetism;
              const transformY = distanceY / magnetism;
              el.style.transform = `translate(${transformX}px, ${transformY}px)`;
            } else {
              el.style.transform = 'translate(0px, 0px)';
            }
          });
        },
      },
    },
  };
  