<template>
  <div class="main-banner main-banner-2">
    <div class="banner-inner">
      <img class="img-fluid banner-bg" :src="require('../assets/images-saha/hero1.png')" alt="#">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="banner-conent">
              <h6 class="title mb-2 mb-md-4">Innovating Today </h6>
              <div class="conent">
                
                <p class="small-text">
                  "The present is theirs; the future, for which I really worked,
                  is mine." --- Nikola Tesla
                </p>
                <h4 class="title">
                  <img class="img-fluid banner-icon" :src="require('../assets/banner/banner-02/01.png')" alt="Banner Icon" />

                  For Better Tomorrow.
                  
                </h4>
              </div>
              <div class="row justify-content-start align-items-center">
                <div class="col-lg-3 col-md-4 col-sm-4">
                  <a  @click="goToContactForm" v-attract-hover="{ proximity: 80, magnetism: 5 }" class="btn btn-effect" href="#">
                    <span>Get A Quote</span>
                    <svg
                      width="20"
                      height="22"
                      viewBox="0 0 20 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_59_253)">
                        <path
                          d="M19.4854 11.4293L17.0513 12.221C13.1214 13.4993 10.3036 16.9595 9.84784 21.0668C9.49371 16.981 6.71926 13.5081 2.81255 12.2604L0.210283 11.4293"
                          stroke="white"
                          stroke-width="2"
                        />
                        <path d="M9.83594 20.8889L9.83594 0" stroke="white" stroke-width="2" />
                      </g>
                      <defs>
                        <clipPath id="clip0_59_253">
                          <rect
                            width="21.3333"
                            height="20"
                            fill="white"
                            transform="translate(20) rotate(90)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-5">
                  <div class="counter counter-style-1 pt-0">
                    <div>
                      
                      <div class="counter-info">
                        <span class="counter-title">Customer Satisfaction</span>
                        
                      <span>
                          <v-progress-circular
                            :value="counterValue"
                            color="primary"
                            size="100"
                            width="10"
                          >
                            
                              <span class="counter-number">{{ counterValue }}</span><span class="suffix">%</span>
                            
                          </v-progress-circular>
                          <v-progress-linear color="primary"  :model-value="counterValue" :height="12"></v-progress-linear>
                        </span>
                       </div>
                
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  </div>
  <section class="space-ptb z-index-2 bg-black">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="section-title text-center">
            <span class="sub-title"><img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt=""> Our Mission</span>
            <h2 class="title"> From Vision to Reality: Revolutionizing the Future, Together.</h2>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="case-studies-grid grid-wrapper grid-xl-3 grid-md-2 grid-sm-1">
             <div class="case-studies-wrapper case-studies-style-1">
              <div class="case-studies-img">
                <img class="img-fluid img-height" :src="require('../assets/images-saha/innovation.png')" alt="" />
                <a href="#" class="category">Innovation</a>
              </div>
              <div class="case-studies-info">
                <div class="case-studies-info-inner">
                  <h3 class="case-studies-title"><a>Building Cool Products</a></h3>
                   <div class="case-studies-content">
                    <div class="case-studies-description">At SAHA Nexus, we are passionate about developing cutting-edge software products that push the boundaries of what's possible.</div>
                   </div>
                  
                </div>
              </div>
            </div>
            <div class="case-studies-wrapper case-studies-style-1">
              <div class="case-studies-img">
                <img class="img-fluid img-height" :src="require('../assets/images-saha/collaboration.png')" alt="" />
                <a class="category">Collaboration</a>
              </div>
              <div class="case-studies-info">
                <div class="case-studies-info-inner">
                  <h3 class="case-studies-title"><a>Empowering IDEAS</a></h3>
                   <div class="case-studies-content">
                    <div class="case-studies-description">We partner with our anyone  to turn their IDEAS into transformative digital solutions, leveraging our technical expertise.
                      </div>
                   </div>
                  
                </div>
              </div>
            </div>
            <div class="case-studies-wrapper case-studies-style-1">
              <div class="case-studies-img">
                <img class="img-fluid img-height" :src="require('../assets/images-saha/revindustries.png')" alt="" />
                <a class="category">Bussiness Model</a>
              </div>
              <div class="case-studies-info">
                <div class="case-studies-info-inner">
                  <h3 class="case-studies-title"><a >Revolutionize  Industries</a></h3>
                   <div class="case-studies-content">
                    <div class="case-studies-description">We  create products that revolutionize industries and enhance the way people live and work.</div>
                   </div>
                  
                </div>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
  </section>
  <section class="space-ptb">
    <div class="container">
      <div class="row">
        <div class="col-xxl-7 col-lg-8">
          <div class="section-title mb-lg-0">
            <span class="sub-title"><img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt=""> WHAT WE DO</span>
            <h2 class="title mb-0">This is what we do and we do it perfectly.</h2>
          </div>
        </div>
         <div class="col-xxl-5 col-lg-4 align-self-end">
          <p class="mb-0 ps-xxl-5">This is what we do, executed with perfection. Our dedication and expertise ensure every detail is flawless, setting the standard in our pursuit of excellence.</p>
         </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="services grid-wrapper grid-xl-4 grid-md-2 grid-sm-1 grid-gap-0  mt-5 pt-lg-4">
            <div class="service-bottom-space service-wrapper service-style-2 "><div class="service-inner">
              <div class="service-icon">
               <img class="img-fluid" :src="require('../assets/svg/services/Information-Security.svg')" alt="#">
              </div>
              <div class="service-content">
                <h5 class="service-title">Web Development</h5>
                <div class="list-wrapper">
                  <ul class="step-list list">
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Custom design</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Full-Stack development</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Business intelligence</li>
                  </ul>
                </div>
              </div>
            </div></div>
            <div class="service-wrapper service-style-2 service-top-space"><div class="service-inner">
              <div class="service-icon">
                <img class="img-fluid" :src="require('../assets/svg/services/Data-Synchronization.svg')" alt="#">
              </div>
              <div class="service-content">
                <h5 class="service-title">Mobile Development</h5>
                <div class="list-wrapper">
                  <ul class="step-list list">
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">iOS</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Android</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Hybrid platform</li>
                  </ul>
                </div>
              </div>
            </div></div>
            <div class="service-wrapper service-style-2 service-bottom-space"><div class="service-inner">
              <div class="service-icon">
                 <img class="img-fluid" :src="require('../assets/svg/services/Mobile-Platforms.svg')" alt="#">
              </div>
              <div class="service-content">
                <h5 class="service-title">Designing</h5>
                <div class="list-wrapper">
                  <ul class="step-list list">
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Logo design</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">UI/UX design</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Visual design</li>
                  </ul>
                </div>
              </div>
            </div></div>
            <div class="service-wrapper service-style-2 service-top-space"><div class="service-inner">
              <div class="service-icon">
                <img class="img-fluid" :src="require('../assets/svg/services/Process-Automation.svg')" alt="#">
              </div>
              <div class="service-content">
                <h5 class="service-title">Software Applications</h5>
                <div class="list-wrapper">
                  <ul class="step-list list">
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Process Automation</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Process Optimization</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="">Invent and Simplify</li>
                  </ul>
                </div>
              </div>
            </div>
           </div>
          </div>

        </div>
      </div>

    </div>
  </section>


  <section class="space-small-ptb overflow-hidden z-index-2 bg-black">
    <div class="container-fluid g-0 pt-5">
     <div class="row g-0">
       <div class="col-md-12">
         <div class="marquee-wrapper marquee-border marquee-rotate">
           <div class="marquee-inner">
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon1.png')" alt="" /></span>
               <span class="title">Web Design</span>
             </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon2.png')" alt="" /></span>
               <span class="title">UI/UX Design</span>
             </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon3.png')" alt="" /></span>
               <span class="title">Developer</span>
             </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon4.png')" alt="" /></span>
               <span class="title">Automation</span>
             </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon5.png')" alt="" /></span>
               <span class="title">Software Applications</span>
             </div>
             <div class="marquee-item">
              <span class="icon"> <img class="img-fluid" :src="require('../assets/client-logo/brand-icon5.png')" alt="" /></span>
              <span class="title">Machine Learning</span>
            </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon1.png')" alt="" /></span>
               <span class="title">Web Design</span>
             </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon2.png')" alt="" /></span>
               <span class="title">UI/UX Design</span>
             </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon3.png')" alt="" /></span>
               <span class="title">Developer</span>
             </div>
             <div class="marquee-item">
               <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon4.png')" alt="" /></span>
               <span class="title">Automation</span>
             </div>
             <div class="marquee-item">
               <span class="icon"> <img class="img-fluid" :src="require('../assets/client-logo/brand-icon5.png')" alt="" /></span>
               <span class="title">Machine Learning</span>
             </div>
           </div>
         </div>
       </div>
     </div>
   </div>
  </section>


  <section class="space-ptb  z-index-2 bg-black ellipse-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="sticky-top" style="top: 80px;">
            <div class="section-title mb-0">
              <span class="sub-title"><img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt="" /> How It Works</span>
              <h2 class="title">Smart strategy & excellent performance</h2>
            </div>
            <div class="ps-xxl-5 ms-0 ms-md-5 pb-5 pb-lg-0">
              <p class="mb-5">We all carry a lot of baggage, thanks to our upbringing. The majority of people carry with them, an entire series of self-limiting beliefs.</p>
            
             </div>
          </div>
        </div>
        <div class="col-lg-7">
          <div class="steps-wrapper">
            <!-- Step 01 -->
            <div class="steps-item">
              <div class="step-arrow">
                <a class="btn-arrow" >
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_923_136)">
                      <path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/>
                      <path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_923_136">
                        <rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
              <div class="step-info">
                <span class="step-number">Step 01</span>
                <h3 class="step-title">Share Your Vision</h3>
                <p>Your idea is just the beginning. We take the time to truly understand your needs and vision, exploring every possibility to ensure nothing is overlooked.</p>
                <div class="list-wrapper">
                  <ul class="step-list list col-2">
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Conceptualization</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Feasibility Analysis</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Initial Design Strategy</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Platform Selection</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Goal Setting</li>
                  </ul>
                </div>
                <div class="step-image"><img class="img-fluid" :src="require('../assets/images-saha/vision.png')" alt=""></div>
              </div>
            </div>
            
            <!-- Step 02 -->
            <div class="steps-item">
              <div class="step-arrow">
                <a class="btn-arrow" >
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_923_137)">
                      <path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/>
                      <path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_923_137">
                        <rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
              <div class="step-info">
                <span class="step-number">Step 02</span>
                <h3 class="step-title">Collaborate and Plan</h3>
                <p>We take your idea further, offering expert insights, technical recommendations, and a clear roadmap to success. Collaboration is key to refining your vision.</p>
                <div class="list-wrapper">
                  <ul class="step-list list col-2">
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Technical Specifications</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Development Plan</li>
                  </ul>
                </div>
                <div class="step-image"><img class="img-fluid" :src="require('../assets/images-saha/planning.png')" alt=""></div>
              </div>
            </div>
            
            <!-- Step 03 -->
            <div class="steps-item">
              <div class="step-arrow">
                <a class="btn-arrow" >
                  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_923_138)">
                      <path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/>
                      <path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/>
                    </g>
                    <defs>
                      <clipPath id="clip0_923_138">
                        <rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/>
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
              <div class="step-info">
                <span class="step-number">Step 03</span>
                <h3 class="step-title">Refine and Execute</h3>
                <p>We don’t just stop at the initial plan. Our team works with you to refine, adjust, and ensure the project exceeds your expectations before full execution.</p>
                <div class="list-wrapper">
                  <ul class="step-list list col-2">
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Feedbacks </li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Revisions</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Finalization</li>
                    <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Continuous Improvement</li>
                  </ul>
                </div>
                <div class="step-image"><img class="img-fluid" :src="require('../assets/images-saha/execute.png')" alt=""></div>
              </div>
            </div>
          </div>
        </div>
        
      </div>

      <section class="space-small-ptb overflow-hidden z-index-2 bg-black">
        <div class="container-fluid g-0 pt-5">
          <div class="row g-0">
            <div class="col-md-12">
              <div class="marquee-wrapper marquee-border marquee-rotate">
                <div class="marquee-inner">
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon1.png')" alt="" /></span>
                    <span class="title">JavaScript</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon2.png')" alt="" /></span>
                    <span class="title">Vue</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon3.png')" alt="" /></span>
                    <span class="title">React</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon4.png')" alt="" /></span>
                    <span class="title">Ant Design</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon5.png')" alt="" /></span>
                    <span class="title">PrimeVue</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon1.png')" alt="" /></span>
                    <span class="title">Django</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon2.png')" alt="" /></span>
                    <span class="title">GitHub</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon3.png')" alt="" /></span>
                    <span class="title">AWS</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon4.png')" alt="" /></span>
                    <span class="title">GCP</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon5.png')" alt="" /></span>
                    <span class="title">Azure</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon1.png')" alt="" /></span>
                    <span class="title">Vuetify</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon2.png')" alt="" /></span>
                    <span class="title">Tailwind CSS</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon3.png')" alt="" /></span>
                    <span class="title">HTML</span>
                  </div>
                  <div class="marquee-item">
                    <span class="icon"><img class="img-fluid" :src="require('../assets/client-logo/brand-icon4.png')" alt="" /></span>
                    <span class="title">CSS</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      <!-- projects customers data -->
      <!-- <div class="row mt-5 pt-lg-5 justify-content-center">
        <div class="col-md-4 col-sm-6 mb-4 mb-md-0">
          <div class="counter counter-style-2 h-100">
            <div class="counter-number"><span class="timer mb-0" data-to="5" data-speed="2000">5</span><span class="suffix">M</span></div>
            <div class="counter-info"><span class="counter-title text-white">Business Peoples</span></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6  mb-4 mb-md-0">
          <div class="counter counter-style-2 h-100">
            <div class="counter-number"><span class="timer mb-0" data-to="30" data-speed="2000">30</span><span class="suffix">K+</span></div>
            <div class="counter-info"><span class="counter-title text-white">Project completed</span></div>
          </div>
        </div>
        <div class="col-md-4 col-sm-6">
          <div class="counter counter-style-2 h-100">
            <div class="counter-number"><span class="timer mb-0" data-to="10" data-speed="2000">10</span><span class="suffix">K+</span></div>
            <div class="counter-info"><span class="counter-title text-white">Happy Customers</span></div>
          </div>
        </div>
      </div> -->
    </div>
  </section>


  <section class="space-ptb testimonial-section overflow-hidden ellipse-top bg-black">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
          <div class="section-title text-center">
            <span class="sub-title "><img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt=""> Our Testimonials</span>
            <h2 class="title"> Think Big, Developed and Delivered Results</h2>
          </div>
        </div>
      </div>

      <v-row justify="start">
        <v-col cols="11">
          <v-carousel show-arrows="hover" cycle
           hide-delimiters class="custom-slide-group" >
            <v-carousel-item v-for="(testimonial, index) in testimonials" :key="index" class="cardsection" >
              <v-card class="cardsection">
                <div class="testimonial-wrapper testimonial-style-2">
                  <v-row>
                    <v-col>
                      <v-rating
                        v-model="testimonial.rating"
                        color="yellow"
                        dense
                        readonly
                        background-color="grey"
                      ></v-rating>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col class="testimonial-quote">
                      <v-img
                      
                      class="img-fluid"
                        :src="require('../assets/quote-icon-01.png')"
                        alt="Quote Icon"
                        max-width="50"
                      />
                    </v-col>
                  </v-row>

                  <v-card-text class="testimonial-content">
                    <p>{{ testimonial.content }}</p>
                  </v-card-text>

                  <div class="testimonial-author">
                    <div class="author-info">
                      <h6 class="author-name">{{testimonial.author}}</h6>
                      <div class="author-position1">{{testimonial.position}}</div>
                    </div>
                  </div>
                </div>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-col>
      </v-row>
    </div>
  </section>

</template>

<script>

import AttractHoverDirective from '../plugins/attractHover';
export default {
  name: "LandingPage",
  directives: AttractHoverDirective.directives,
  data() {
    return {
      counterValue: 0,
      testimonials: [
  {
    rating: 5,
    content: "The e-commerce pharmacy platform has streamlined our operations significantly. It's intuitive and helps us serve our customers more efficiently. Great job on delivering exactly what we needed!",
    author: "Dr. Mano",
    position: "Doctor"
  },
  {
    rating: 5,
    content: "The website perfectly captures my artistic vision! It’s sleek, modern, and user-friendly, which has really helped showcase my designs to potential clients. Highly recommended!",
    author: "Priyanka",
    position: "Designer & Digital Artist"
  },
  {
    rating: 5,
    content: "The e-commerce app has been a game changer for my grocery business. It’s easy for customers to use, and we’ve seen a significant boost in sales. The team delivered beyond expectations.",
    author: "Srinivas",
    position: "Supermarket Owner"
  },
  {
    rating: 5,
    content: "The car service and sales application you built for us has transformed the way we manage customers and sales. It’s efficient, well-designed, and our customers love the convenience!",
    author: "Praveen",
    position: "Car Service & Sales Owner"
  }
]
 // Start from 0
    };
  },
  mounted() {
    this.animateCounter();
  },
  methods: {
    goToContactForm() {
      this.$router.push({ path: '/contact', hash: '#contact-form' });
    },
    animateCounter() {
      const targetValue = 100; // Target value for the counter
      const duration = 2000; // Duration in ms (2 seconds)
      const stepTime = Math.abs(Math.floor(duration / targetValue));

      const timer = setInterval(() => {
        if (this.counterValue < targetValue) {
          this.counterValue += 1;
        } else {
          clearInterval(timer); // Stop when it reaches 100
        }
      }, stepTime);
    },
  },
};
</script>

<style scoped>
.author-position1 {
  font-size: 1rem !important;
  color: white !important;
  display: block !important; /* Ensures it's visible */
  visibility: visible !important;
}
@media only screen and (max-width: 600px) {
  .author-position1 {
    font-size: 0.5rem !important;
    color: white !important;
  }
}
.img-height{
  height:500px;
}


.cardsection{
  padding: 2%
}


.v-slide-group {
  overflow-x: scroll;
}

.custom-slide-group {
  overflow-x: hidden; /* Hides the scrollbar */
  scroll-behavior: smooth; /* Allows smooth scrolling */
}
.v-card {
  background-color: #000000;
  color: #fff;
}
.banner-bg{
  overflow: hidden;
  position: absolute;
  left: 300px;
  padding: 200px;
  top: 200px;

}
@media (max-width: 768px) {
  .banner-bg {
    overflow: hidden;
    position: absolute;
    left: 0;   /* Align to the left of the screen */
    top: 0;    /* Align to the top of the screen */
    padding: 20px;  /* Adjust padding to be more mobile-friendly */
    width: 100%;  /* Ensure it spans the width of the mobile screen */
    height: auto; /* Adjust height based on content */
  }
}
</style>


