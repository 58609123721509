<template>
  <div class="site-content">
    <!-- SEO Meta Tags -->
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta name="description" content="Discover our flexible pricing plans tailored to meet your project needs, from simple websites to advanced e-commerce solutions.">
    <meta name="keywords" content="Pricing, Web Development, E-commerce Solutions, Responsive Websites, Custom Solutions">
    <meta name="author" content="Saha Nexus Corporation">

    <title>Our Pricing Plans - Saha Nexus Corporation</title>

    <!--=================================
    Page Header -->
    <div class="inner-header bg-holder" :style="{ backgroundImage: `url(${require('../assets/images-saha/Maximum.png')})` }">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 text-center">
            <h1 class="title">Our Pricing</h1>
          </div>
        </div>
      </div>
    </div>

    <div class="content-wrapper">

      <!--=================================
      Pricing & Testimonial -->
      <section class="space-ptb z-index-2 bg-black ellipse-top">
        <div class="container">
          <div class="row">
            <div class="col-lg-5">
              <div class="sticky-top" style="top: 80px;">
                <div class="section-title mb-0">
                  <span class="sub-title"><img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt="Subtitle Icon" /> Our Pricing Plans</span>
                  <h2 class="title">Flexible Pricing, Tailored Solutions</h2>
                </div>
                <div class="ps-xxl-5 ms-0 ms-md-5 pb-5 pb-lg-0">
                  <p class="mb-5">
                    We offer dynamic pricing based on the scope and complexity of the project, ensuring value at every step. Whether you need a simple website or a comprehensive platform, we have you covered.
                  </p>
                </div>
              </div>
            </div>
      
            <div class="col-lg-7">
              <div class="steps-wrapper">

                <!-- Plan Type 01 -->
                <div class="steps-item">
                  <div class="step-arrow">
                    <a class="btn-arrow">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_923_136)">
                          <path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/>
                          <path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_923_136">
                            <rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="step-info">
                    <span class="step-number">Plan 01</span>
                    <h3 class="step-title">Static & Responsive Websites</h3>
                    <p>For clients seeking a simple, elegant, and fully responsive website. Ideal for portfolios, small businesses, and informational sites.</p>
                    <div class="list-wrapper">
                      <ul class="step-list list col-2">
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Price: $200 - $500</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Includes Design & Development</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Fully Responsive</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />SEO Optimized</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Fast Turnaround</li>
                      </ul>
                    </div>
                    <div class="step-image">
                      <img class="img-fluid" :src="require('../assets/images-saha/vision.png')" alt="Vision for Static & Responsive Websites">
                    </div>
                  </div>
                </div>

                <!-- Plan Type 02 -->
                <div class="steps-item">
                  <div class="step-arrow">
                    <a class="btn-arrow">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_923_137)">
                          <path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/>
                          <path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_923_137">
                            <rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="step-info">
                    <span class="step-number">Plan 02</span>
                    <h3 class="step-title">Dynamic & E-commerce Platforms</h3>
                    <p>Designed for businesses needing a custom solution with advanced functionality like booking systems or online stores.</p>
                    <div class="list-wrapper">
                      <ul class="step-list list col-2">
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Price: $500 - $2000</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />E-commerce Integration</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Payment Gateways</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Advanced User Authentication</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Shipping & Product Management</li>
                      </ul>
                    </div>
                    <div class="step-image">
                      <img class="img-fluid" :src="require('../assets/images-saha/vision.png')" alt="Vision for Dynamic & E-commerce Platforms">
                    </div>
                  </div>
                </div>

                <!-- Plan Type 03 -->
                <div class="steps-item">
                  <div class="step-arrow">
                    <a class="btn-arrow">
                      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_923_138)">
                          <path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/>
                          <path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/>
                        </g>
                        <defs>
                          <clipPath id="clip0_923_138">
                            <rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/>
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="step-info">
                    <span class="step-number">Plan 03</span>
                    <h3 class="step-title">Full-stack Applications</h3>
                    <p>Comprehensive solutions for startups and enterprises, offering complete front-end and back-end development.</p>
                    <div class="list-wrapper">
                      <ul class="step-list list col-2">
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Custom Pricing Based on Requirements</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Scalable Architecture</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Database Management</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />API Integration</li>
                        <li><img class="img-fluid" :src="require('../assets/arrow-right.png')" alt="" />Ongoing Support</li>
                      </ul>
                    </div>
                    <div class="step-image">
                      <img class="img-fluid" :src="require('../assets/images-saha/vision.png')" alt="Vision for Full-stack Applications">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>>
 
  </div>
  
</template>


<script>
export default {
  name: 'PricingPage',
  computed: {
    structuredData() {
      return {
        "@context": "https://schema.org",
        "@type": "Product",
        "name": "Web Development Services",
        "description": "Tailored web development services including static websites, dynamic platforms, and full-stack applications.",
        "offers": [
          {
            "@type": "Offer",
            "name": "Static & Responsive Websites",
            "priceCurrency": "USD",
            "price": "200-500",
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock"
          },
          {
            "@type": "Offer",
            "name": "Dynamic & E-commerce Platforms",
            "priceCurrency": "USD",
            "price": "500-2000",
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock"
          },
          {
            "@type": "Offer",
            "name": "Full-stack Applications",
            "priceCurrency": "USD",
            "price": "Custom Pricing",
            "itemCondition": "https://schema.org/NewCondition",
            "availability": "https://schema.org/InStock"
          }
        ]
      };
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(this.structuredData);
    document.head.appendChild(script);
  }
}
</script>

<style scoped>
.pricing-card {
  padding: 16px;
}
.text-white{
color: white;
}
.title{
  font-size: 3rem !important;
  
}
</style>
