<template lang="">
    <div class="site-content">
        <!--=================================
        Page Header -->  
         <div class="inner-header bg-holder" :style="{ backgroundImage: `url(${require('../assets/banner/inner-header/page-header-01.jpg')})` }">

         <div class="container">
           <div class="row  justify-content-center">
             <div class="col-md-12 text-center">
               <h1 class="title">Blog</h1>
             
             </div>
           </div>
         </div>
        </div>

        <div class="content-wrapper">

     <section class="space-ptb ellipse-top ellipse-bottom">
        <div class="container">
          <div class="row justify-content-start">
            <div class="col-lg-12">
              <div class="blog-items grid-wrapper grid-xl-3 grid-md-2 grid-sm-1">
                <div class="blog-post-wrapper blog-style-1">
                  <div class="blog-post-info">
                    <div class="post-meta">
                      <div class="post-meta-date">December 12, 2023</div>
                    </div>
                    <h5 class="post-title"><a href="#">Ways of lying to yourself about.</a></h5>
                  </div>
                  <div class="blog-post-img"><img class="img-fluid" :src="require('../assets/blog/01.jpg')" alt="" /></div>
                  <div class="blog-action-info">
                    <h5 class="post-category"><a href="#">Branding</a></h5>
                    <div class="post-link">
                      <a class="btn-arrow" href="#"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_923_133)"><path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/><path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/></g><defs><clipPath id="clip0_923_133"><rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/></clipPath></defs></svg></a>
                    </div>
                  </div>
                </div>
                <div class="blog-post-wrapper blog-style-1">
                  <div class="blog-post-info">
                    <div class="post-meta">
                      <div class="post-meta-date">February 24, 2024</div>
                    </div>
                    <h5 class="post-title"><a href="#">Ways of lying to yourself about.</a></h5>
                  </div>
                  <div class="blog-post-img"><img class="img-fluid" :src="require('../assets/blog/02.jpg')" alt="" /></div>
                  <div class="blog-action-info">
                    <h5 class="post-category"><a href="#">Operations</a></h5>
                    <div class="post-link">
                      <a class="btn-arrow" href="#"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_923_134)"><path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/><path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/></g><defs><clipPath id="clip0_923_134"><rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/></clipPath></defs></svg></a>
                    </div>
                  </div>
                </div>
                <div class="blog-post-wrapper blog-style-1">
                  <div class="blog-post-info">
                    <div class="post-meta">
                      <div class="post-meta-date">May 26, 2024</div>
                    </div>
                    <h5 class="post-title"><a href="#">Ways of lying to yourself about.</a></h5>
                  </div>
                  <div class="blog-post-img"><img class="img-fluid" :src="require('../assets/blog/03.jpg')" alt="" /></div>
                  <div class="blog-action-info">
                    <h5 class="post-category"><a href="#">Branding</a></h5>
                    <div class="post-link">
                      <a class="btn-arrow" href="#"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_923_135)"><path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/><path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/></g><defs><clipPath id="clip0_923_135"><rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/></clipPath></defs></svg></a>
                    </div>
                  </div>
                </div>

                <div class="blog-post-wrapper blog-style-1">
                  <div class="blog-post-info">
                    <div class="post-meta">
                      <div class="post-meta-date">May 26, 2024</div>
                    </div>
                    <h5 class="post-title"><a href="#">Ways of lying to yourself about.</a></h5>
                  </div>
                  <div class="blog-post-img"><img class="img-fluid" :src="require('../assets/blog/04.jpg')" alt="" /></div>
                  <div class="blog-action-info">
                    <h5 class="post-category"><a href="#">Branding</a></h5>
                    <div class="post-link">
                      <a class="btn-arrow" href="#"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_923_136)"><path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/><path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/></g><defs><clipPath id="clip0_923_136"><rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/></clipPath></defs></svg></a>
                    </div>
                  </div>
                </div>
                <div class="blog-post-wrapper blog-style-1">
                  <div class="blog-post-info">
                    <div class="post-meta">
                      <div class="post-meta-date">May 26, 2024</div>
                    </div>
                    <h5 class="post-title"><a href="#">Ways of lying to yourself about.</a></h5>
                  </div>
                  <div class="blog-post-img"><img class="img-fluid" :src="require('../assets/blog/05.jpg')" alt="" /></div>
                  <div class="blog-action-info">
                    <h5 class="post-category"><a href="#">Branding</a></h5>
                    <div class="post-link">
                      <a class="btn-arrow" href="#"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_923_137)"><path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/><path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/></g><defs><clipPath id="clip0_923_137"><rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/></clipPath></defs></svg></a>
                    </div>
                  </div>
                </div>
                <div class="blog-post-wrapper blog-style-1">
                  <div class="blog-post-info">
                    <div class="post-meta">
                      <div class="post-meta-date">May 26, 2024</div>
                    </div>
                    <h5 class="post-title"><a href="#">Ways of lying to yourself about.</a></h5>
                  </div>
                  <div class="blog-post-img"><img class="img-fluid" :src="require('../assets/blog/06.jpg')" alt="" /></div>
                  <div class="blog-action-info">
                    <h5 class="post-category"><a href="#">Branding</a></h5>
                    <div class="post-link">
                      <a class="btn-arrow" href="#"><svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_923_138)"><path d="M8.70801 0.959961L9.29825 2.7665C10.2512 5.68321 12.8308 7.77453 15.8928 8.1128C12.8468 8.37564 10.2578 10.4348 9.3276 13.3343L8.70801 15.2657" stroke="inherit" stroke-width="2"/><path d="M15.7602 8.12158H0.1875" stroke="inherit" stroke-width="2"/></g><defs><clipPath id="clip0_923_138"><rect width="15.904" height="14.8437" fill="inherit" transform="translate(0.1875 0.578125)"/></clipPath></defs></svg></a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
 
      </section>
    </div>
</div>
</template>
<script>
export default {
    name:'BlogPage'
}
</script>
<style lang="">
    
</style>