<template lang="">
  <div class="site-content">
    <div class="container-fluid">
      <div class="item-efftect container">
        <div class="efftect overflow-hidden"></div>
        <div class="efftect overflow-hidden"></div>
        <div class="efftect overflow-hidden"></div>
        <div class="efftect overflow-hidden"></div>
        <div class="efftect overflow-hidden"></div>
      </div>
    </div>

    <div class="content-wrapper">
      <section class="space-ptb z-index-2 mt-5">
        <div class="container">
          <div class="row">
            <div class="col-xl-12 col-lg-6">
              <div class="section-title is-sticky">
                <h2 class="title">
                  Get in touch with us. We love talking about digital strategy
                </h2>
                <p>
                  So, make the decision to move forward. Commit your decision to
                  paper, just to bring it into focus. Then, go for it!
                </p>
              </div>
            </div>
            <div class="col-xl-6 col-lg-7">
              <div class="location-items grid-wrapper grid-xl-2 grid-lg-2 grid-md-2 grid-sm-2 grid-xs-1"></div>
            </div>
          </div>
        </div>

        <section class="space-pt ellipse-top bg-black">
          <div class="space-pb ellipse-bottom">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-md-10">
                  <div class="section-title text-center">
                    <span class="sub-title d-flex justify-content-center">
                      <img class="img-fluid" :src="require('../assets/subtitle-icon.png')" alt="" />
                      For Quotation
                    </span>
                    <h2 class="title">Need assistance? please fill the form</h2>
                  </div>
                  <div class="form-wrapper" id="contact-form">
                    <form class="contact-form form-style-border" @submit.prevent="submitForm">
                      <div class="row">
                        <div class="col-md-4">
                          <input type="text" class="form-control" v-model="formData.name" placeholder="Name" aria-label="Name" required />
                        </div>
                        <div class="col-md-4">
                          <input type="email" class="form-control" v-model="formData.email" placeholder="Email" aria-label="Email" required />
                        </div>
                        <div class="col-md-4">
                          <input type="text" class="form-control" v-model="formData.phone" placeholder="Phone" required />
                        </div>
                        <div class="col-lg-12">
                          <textarea class="form-control" rows="6" v-model="formData.message" placeholder="Message" required></textarea>
                        </div>
                        <div class="col-lg-12">
                          <button class="btn btn-effect" type="submit">
                            <span>Send Message</span>
                            <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g clip-path="url(#clip0_59_253)">
                                <path d="M19.4854 11.4293L17.0513 12.221C13.1214 13.4993 10.3036 16.9595 9.84784 21.0668C9.49371 16.981 6.71926 13.5081 2.81255 12.2604L0.210283 11.4293" stroke="white" stroke-width="2" />
                                <path d="M9.83594 20.8889L9.83594 0" stroke="white" stroke-width="2" />
                              </g>
                              <defs>
                                <clipPath id="clip0_59_253">
                                  <rect width="21.3333" height="20" fill="white" transform="translate(20) rotate(90)" />
                                </clipPath>
                              </defs>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </form>

                    <!-- Thank You Dialog -->
                    <v-dialog
                    v-model="showThankYouDialog"
          class="thank-you-dialog"
                   
                  >
                  <div class=" thank-you-dialog">
                    <button type="button" @click="showThankYouDialog=false" class="close" data-dismiss="alert" aria-hidden="true">×</button>
                    <div class="icon"><i class="fa fa-check"></i></div>
                    <div class="dialog">Thanks! for contacting us.</div> 
                    <div class="dialog">We will get back to you soon.</div>

                </div>
              
            
                  </v-dialog>
             
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactPage",
  data() {
    return {
      formData: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      showThankYouDialog: false,
    };
  },
  methods: {
    async submitForm() {
  const formUrl = "https://formspree.io/f/xanyyqzo"; // Replace with your Formspree form ID

  try {
    const response = await fetch(formUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.formData),
    });

    if (response.ok) {
      // Show alert message instead of dialog
      alert("Thanks for contacting us. We will get back to you soon!");

      // Clear form data
      this.formData = {
        name: "",
        email: "",
        phone: "",
        message: "",
      };

      // Commented out dialog for now
      // this.showThankYouDialog = true;
      // Hide the dialog after 5 seconds

    } else {
      console.error("Form submission failed");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
  }
}

  },
};
</script>

<style >
.dialog{

    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    font-weight: 700;

    margin-bottom: 15px;
    background: linear-gradient(60deg, #ffffff -20%, #77BEFF 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

}
.thank-you-dialog {

  color: white; /* White text */
 width:800px;
 background-color: black;
 padding: 10px;
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 15px rgba(255, 255, 255, 0.3); /* Shadow effect */
  position: fixed; /* Fixed position */
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for centering */

  transition: opacity 0.3s ease; /* Smooth transition for fading */
 
}
.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .2;
}

.close:hover,.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  opacity: .5;
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: bold;
}

.alert>p,.alert>ul {
  margin-bottom: 0;
}

.alert>p+p {
  margin-top: 5px;
}

.alert-dismissable {
  padding-right: 35px;
}

.alert-dismissable .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  background-color: #000000;
  border-color: #000000;
  color: #000000;
}

.alert-success hr {
  border-top-color: #000000;
}

.alert-success .alert-link {
  color: #000000;
}

</style>
